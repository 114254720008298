import styled, { css } from 'styled-components';

export const StyledTabs = styled.div`
  justify-content: flex-start;
  display: flex;
  gap: 40px;
  width: 100%;
  position: relative;
  overflow-x: scroll;
  align-self: end;

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
  }

  &:after {
    content: '';
    bottom: 1.5px;
    position: absolute;
    display: block;
    width: 100%;
    border-radius: 0.5px;
    z-index: -1;
  }
`;

export const StyledTabEmpty = styled.div`
  display: none;
`;

interface TabProps {
  isActive?: boolean;
}

export const StyledTab = styled.div<TabProps>`
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        content: '';
        margin-top: 8px;
        display: block;
        width: 100%;
        border-top: 2px solid #476bc3;
        border-bottom: 2px solid #476bc3;
        border-radius: 2px;
      }
    `}
`;
