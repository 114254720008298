import styled from 'styled-components';
import Select from 'react-select';

interface DropdownProps {
  menuIsOpen: boolean;
}

export const OptionLabel = styled.p<{ selected: boolean }>`
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: ${({ selected }) => (selected ? '#394a8c' : '#393533')};
`;

export const CustomPlaceholder = styled.p<{ hasValue: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #324fa5;
  margin: 0;
`;

export const DropdownWrapper = styled.div<DropdownProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .dropdown-icon {
    transition: all 0.25s linear;
  }

  .dropdown-icon.open {
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
`;

export const StyledSelect = styled(Select)`
  .Select__control {
    height: 40px;
    width: 100%;
    cursor: pointer;

    background: #fff;
    box-sizing: border-box;
    border-radius: 8px;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      border-color: #605b5b;
    }

    &--is-focused {
      box-shadow: none;
      outline: none;
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
    }
  }

  .Select__placeholder {
    margin: 0;
  }

  .Select__single-value {
    color: #324fa5;
    margin: 0;
  }

  .Select__value-container {
    padding: 0;
    margin: 0;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__clear-indicator ~ .Select__indicator-separator {
    display: block;
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__clear-indicator {
    &:hover path {
      fill: #262321;
    }
  }

  .Select__menu {
    color: #3c3d3e;
    padding: 8px;
    z-index: 999;
    position: absolute;
    right: 0;
    width: 190px;
  }

  .Select__option {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding: 5px 8px;
    box-sizing: border-box;
    border-radius: 6px;

    &--is-selected {
      background-color: #e8effa;
    }

    &--is-disabled {
      user-select: none;
      pointer-events: none;
      cursor: default;
      background: #f2f0ed;
      border: 1px solid #cdc7c2;
    }
  }
`;
